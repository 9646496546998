import * as React from "react";

export const GtagInit = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.googletagmanager.com/gtag/js?id=AW-999419255";
    script.async = true;
    document.body.appendChild(script);
    // @ts-ignore
    window.dataLayer = window.dataLayer || [];
    // @ts-ignore
    window.gtag =
      // @ts-ignore
      window.gtag ||
      function () {
        // @ts-ignore
        dataLayer.push(arguments);
      };
    // @ts-ignore
    gtag("js", new Date());
    // @ts-ignore
    gtag("consent", "default", {
      ad_storage: "denied",
      ad_user_data: "denied",
      ad_personalization: "denied",
      analytics_storage: "denied",
    });
    // @ts-ignore
    gtag("config", "AW-999419255");
  }, []);
  return <div />;
};

/*
  <div
    dangerouslySetInnerHTML={{
      __html: `
      <!-- Global site tag (gtag.js) - Google Ads: 999419255 --> 
      <script async src="https://www.googletagmanager.com/gtag/js?id=AW-AW-999419255"></script>
      <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-999419255'); </script>
      `,
    }}
/>
*/

export const TrackConversion = () => {
  React.useEffect(() => {
    // @ts-ignore
    gtag("event", "conversion", {
      send_to: "AW-999419255/tv8hCKDHmfkCEPfax9wD",
    });
  }, []);
  return <div />;
};

/*
    <div
    dangerouslySetInnerHTML={{
      __html: `
    <!-- Event snippet for LEADS DECENNALE conversion page --> <script> gtag(‘event’, ‘conversion’, {‘send_to’: ‘AW-999419255/tv8hCKDHmfkCEPfax9wD’}); </script>
    `,
    }}
    />
*/
